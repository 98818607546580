import TablePage from '@/found/components/table_page';
import { VXETable } from 'vxe-table';
import request from '@/found/utils/request';
import _ from 'xe-utils';
// import Form from '../form';

export default {
  extends: TablePage,
  components: {
    // Form,
  },
  data() {
    return {
      params: {
        isReport: 'Y',
      },
    };
  },

  created() {
    this.getConfigList('personnel_entry_report_list');
  },
  methods: {

    modalClick({ val, row }) {
      if (val.code === 'download_file') {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = row.urlPath;
        link.setAttribute('download', row.fileName); // 文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(link.href); // 释放掉blob对象
      }
      return true;
    },
  },
};
